import React, { useState, useEffect } from "react";
import { Map, MapMarker, CustomOverlayMap } from "react-kakao-maps-sdk";

function Main(props) {
  const [initLat, setInitLat] = useState(37.496542166563);
  const [initLng, setInitLng] = useState(127.105729381413);

  const [positions, setPositions] = useState([]);
  const [clickedId, setClickedId] = useState(0);

  useEffect(() => {
    window.addEventListener("message", (e) => {
      const { data, type } = JSON.parse(e.data);

      if (type === "clicked_init") {
        setClickedId(0);

        if (positions.length > 0) {
          setPositions([...positions]);
        }
      }

      if (type === "current") {
        setInitLat(data.lat);
        setInitLng(data.lon);

        if (data && data.markerList.length > 0) {
          setPositions(data.markerList);
        } else {
          setPositions([]);
        }
      }
    });

    document.addEventListener("message", (e) => {
      const { data, type } = JSON.parse(e.data);

      if (type === "clicked_init") {
        setClickedId(0);

        if (positions.length > 0) {
          setPositions([...positions]);
        }
      }

      if (type === "current") {
        setInitLat(data.lat);
        setInitLng(data.lon);

        if (data && data.markerList.length > 0) {
          setPositions(data.markerList);
        } else {
          setPositions([]);
        }
      }
    });
  }, []);

  // 웹뷰에서 RN으로 데이터를 보낼때 사용합니다.
  const sendToRN = (clickedSpaceId) => {
    if (window.ReactNativeWebView) {
      // RN에서 데이터는 반드시 문자열로 받을 수 있기 때문에
      // JSON.stringify를 사용합니다.
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ clickedSpaceId: clickedSpaceId })
      );
      setClickedId(clickedSpaceId);
    } else {
      // --
    }
  };

  return (
    <>
      <div style={{ flex: 1 }}>
        <Map
          center={{ lat: initLat, lng: initLng }}
          style={{ width: "100%", height: "100vh", flex: 1 }}
          level={1}
        >
          {positions.map((position, index) => (
            <div key={index}>
              <MapMarker
                key={`${index}-${position.id}`}
                position={{
                  lat: position.latitude,
                  lng: position.longitude,
                }} // 마커를 표시할 위치
                image={{
                  src: "/assets/icons/map_icon.png", // 마커이미지의 주소입니다
                  size: {
                    width:
                      clickedId == position.id
                        ? 45
                        : position.id === -1
                        ? 0
                        : 45,
                    height:
                      clickedId == position.id
                        ? 45
                        : position.id === -1
                        ? 0
                        : 45,
                  }, // 마커이미지의 크기입니다
                }}
                title={""} // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
                onClick={() => sendToRN(position.id)}
              />
              {position.id === -1 ? (
                <CustomOverlayMap
                  position={{ lat: position.latitude, lng: position.longitude }}
                  yAnchor={1}
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      style={{
                        width: 64,
                        height: 64,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        borderRadius: 100,
                        border: "8px solid #FFF100",
                      }}
                      src={position.fileUrl}
                      alt="current_imageUrl"
                    />
                  </div>
                </CustomOverlayMap>
              ) : (
                <CustomOverlayMap
                  position={{ lat: position.latitude, lng: position.longitude }}
                  yAnchor={1}
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      style={{
                        width: 26,
                        height: 26,
                        position: "absolute",
                        top: -37,
                        left: -12.6,
                        borderRadius: 100,
                      }}
                      src={position.shopImages[0].fileUrl}
                      alt="imageUrl"
                    />
                  </div>
                </CustomOverlayMap>
              )}
            </div>
          ))}
        </Map>
      </div>
    </>
  );
}

export default Main;
