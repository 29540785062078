import { Route, Switch, Redirect } from "react-router-dom";

import Main from "../pages/main";

const Router = () => {
  return (
    <Switch>
      <Route exact={true} path="/map" component={Main} />

      {/* Not Found */}
      <Route component={() => <Redirect to="/map" />} />
    </Switch>
  );
};

export default Router;
